import './common';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import vant from 'vant'
// import { Lazyload } from 'vant'
// import 'vant/lib/index.css'





// // 注册时可以配置额外的选项
// Vue.use(Lazyload, {
//     lazyComponent: true,
// });

import atm from '@/assets/logo.png';
console.log(atm, 222)
    //引入插件
import VueLazyload from 'vue-lazyload';
//注册插件
Vue.use(VueLazyload, {
    //懒加载默认的图片
    // loading: 'https://picx.zhimg.com/v2-5c1156fdd7a8d6502b7a1b8f355bb909_l.jpg',
    preLoad: 1.3,
    error: atm,
    loading: atm
});

Vue.config.productionTip = false


window.addEventListener('pageshow', (e) => {
    if (e.persisted) {
        location.reload();
    }
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')