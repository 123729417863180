import store from 'store';
import createRndStrId from './createRndStrId.js';

/**
 * 根据传入的时间计算出是距离当前时间多长时间之前
 * @param {Number} timestamp 单位：s
 */
const jsDateDiff = (timestamp) => {
    const currentTimestamp = new Date().getTime() / 1000;
    const diffSec = Math.floor(currentTimestamp - timestamp);

    if (diffSec < 0) {
        return '1秒前';
    }

    const diffMin = Math.floor(diffSec / 60);
    const diffHour = Math.floor(diffSec / 3600);
    const diffDay = Math.floor(diffSec / 86400);
    const diffMonth = Math.floor(diffSec / 2592000);
    const diffYear = Math.floor(diffSec / 31536000);

    if (diffSec < 60) {
        return `${diffSec}秒前`;
    }
    if (diffMin < 60) {
        return `${diffMin}分钟前`;
    }
    if (diffHour < 24) {
        return `${diffHour}小时前`;
    }
    if (diffDay < 30) {
        return `${diffDay}天前`;
    }
    if (diffMonth < 12 || diffYear <= 0) {
        return `${diffMonth}个月前`;
    }

    return `${diffYear}年前`;
};

/**
 * 将 object 转为 string key=value
 * @param {object} obj
 */
const serializeObj = (obj) => {
    const paramArr = [];

    Object.keys(obj).forEach((key) => {
        if (obj[key]) {
            paramArr[paramArr.length] = `${key}=${obj[key]}`;
        }
    });

    return paramArr.length ? paramArr.join('&') : '';
};

/**
 * 是否在微信中运行
 */
function is_weixin() {
    const ua = navigator.userAgent;
    const reg = /MicroMessenger/i;
    return reg.test(ua);
};

/**
 * 是否在阿里系客户端运行
 */
function is_aliapp() {
    const ua = navigator.userAgent;
    const reg = /AliApp/i;
    return reg.test(ua);
}

function is_android() {
    const ua = navigator.userAgent;
    const android = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1;
    if (android) return true;
    return false;
}

// 是否在 QQ 客户端运行
function is_qq() {
    const ua = navigator.userAgent;
    const reg = /qq/i;
    return reg.test(ua);
}

// 是否在移动设备
function is_mobile() {
    return (/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i).test(navigator.userAgent)
}

const sdkJump = (params) => {
    // console.log(23434)
    if (location.href.indexOf('server_token') > -1) {
        return;
    }
    //passportScene 1不需要手机号 2需要手机号
    const passportScene = params && params.notneedphone === 0 ? 2 : 1;
    const redirectUrl = params && params.redirect_url ? params.redirect_url : location.href;

    store.set('historyCnt', history.length);

    var loginUrl = `${window.BasePath}passport/index.html?passport_scene=`+passportScene+'&redirect_url=' + encodeURIComponent(redirectUrl);
    location.href = loginUrl;

};

/**
 * 根据根 url 返回 请求参数 object
 * @param {string} url
 */
const getUrlParams = (url) => {
    if (typeof url !== 'string' || url.length === 0) {
        return {};
    }

    const params = url.substr(url.lastIndexOf('?') + 1).split('&');
    const paramObj = {};

    params.forEach((item) => {
        const index = item.indexOf('=');

        if (index !== -1) {
            paramObj[item.substring(0, index)] = item.substr(index + 1);
        }
    });

    return paramObj;
};

/**
 * 获取当前页面 url 参数
 */
const getRequestParams = () => getUrlParams(location.search);

/**
 * 从字符串 str 中 随机选择 len 个生成随机字符串
 * @param {string} str
 * @param {number} len
 */
const randomString = (str = '', len = 0) => {
    const maxPos = str.length;
    let randomStr = '';

    for (let i = 0; i < len; i++) {
        randomStr += str.charAt(Math.floor(Math.random() * maxPos));
    }
    return randomStr;
};

/**
 * 获取文件后缀名，.jpg || .jpeg || .png || .gif 返回原格式，其他默认返回 jpg
 * 对于部分 Android 机型，比如华为、Vivo等，有些相册中的图片后缀是 `.image:12345`，
 * 对于这种图片，要强制其使用 `.jpg` 后缀。
 * @param {string} filename
 */
const getSuffix = (filename) => {
    const pos = filename.lastIndexOf('.');
    let suffix = '';

    if (pos !== -1) {
        suffix = filename.substring(pos + 1);
    }

    const validSuffix = ['jpg', 'jpeg', 'png', 'gif'];

    if (validSuffix.indexOf(suffix) === -1) {
        [suffix] = validSuffix;
    }

    return suffix;
};

const guid = (length = 16) => {
    let guidStr = '';

    for (let i = 1; i <= length; i++) {
        const n = Math.random() * 16.0;
        guidStr += Math.floor(n).toString(16);
    }

    return guidStr;
};

/**
 * 将日期转化为指定格式的字符，如 yyyy-MM-dd hh:mm:ss
 * @param {Date} date 日期对象
 * @param {string} format 日期格式
 */
const formatDate = (date, format) => {
    let dateStr = format;

    const regObj = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
        'q+': Math.floor((date.getMonth() + 3) / 3),
        'S+': date.getMilliseconds(),
    };

    if (/(y+)/.test(dateStr)) {
        dateStr = dateStr.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
    }

    Object.keys(regObj).forEach((regExp) => {
        if (new RegExp(`(${regExp})`).test(dateStr)) {
            const prefixStr = regExp === 'S+' ? '000' : '00';

            dateStr = dateStr.replace(RegExp.$1, RegExp.$1.length === 1 ? regObj[regExp] : (`${prefixStr}${regObj[regExp]}`).substr(`${regObj[regExp]}`.length));
        }
    });

    return dateStr;
};

const getShareTimestamp = () => `${formatDate(new Date(), 'yyyyMMddhhmmssSSS')}${randomString('0123456789', 8)}`;



const hideWeixinShareMenu = function() {
	if (!is_weixin()) return;

	if (wx && wx.ready) {
		wx.ready(function() {
			wx.hideOptionMenu();
		})
	} else {
		setTimeout(hideWeixinShareMenu, 400);
	}
}

/**
 * 格式化时间戳
 */
const formatTimestapDate = function (tmp) {
    const date = new Date(tmp * 1000)
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const seconds = date.getSeconds();

    return `${year}.${zero(month)}.${zero(day)} ${zero(hour)}:${zero(minute)}:${zero(seconds)}`
}

function zero(num) {
    if (num > 9) {
        return num
    } else {
        return '0' + num
    }
}

/**
 * 金额千分位分隔
 * @param {Number} value
 * @returns
 */
const formatAmount = function (value) {
    let num = value.toString();
    let len = num.length;
    if (len <= 3) {
        return num;
    } else {
        let temp = '';
        let remainder = len % 3;
        if (remainder > 0) { // 不是3的整数倍
            return num.slice(0, remainder) + ',' + num.slice(remainder, len).match(/\d{3}/g).join(',') + temp;
        } else { // 3的整数倍
            return num.slice(0, len).match(/\d{3}/g).join(',') + temp;
        }
    }
}

/* eslint-enable */
export default {
    jsDateDiff,
    serializeObj,
    is_weixin,
    is_aliapp,
    is_android,
    is_qq,
    is_mobile,
    sdkJump,
    getUrlParams,
    getRequestParams,
    getSuffix,
    guid,
    formatDate,
    getShareTimestamp,
    createRndStrId,
    hideWeixinShareMenu,
    formatTimestapDate,
    formatAmount,
};
