import Vue from 'vue'
import Vuex from 'vuex'
import store from 'store';

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        projuuid: '',
        user_id: '',
        issuer_face_url: "", // 用户头像
        issuer_name: "", // 用户昵称
        project_title: '', // 项目标题
        images: [], // 项目图片
        introduction: '', // 项目介绍
        raisefunds_number: 0, // 目标金额
        accumulative_number: 0, // 已筹金额
        referee_phone: '', // 推荐人手机号
        support_num: 0, // 支持次数
        supports: [], // 支持者列表
        project_state: 0, // 项目状态 init  1:开启 2:暂停
        is_manager: false, // 是否是发起人身份
        is_support: false, // 是否支持项目
        project_thumb: '', // 项目缩略图
        current_popup: '', // 展示哪个弹窗
        current_sub_popup: '', // 展示哪个子弹窗
        confirm_num: 0,
        confirms: [],
        patient_info: null, // 患者信息
        payee_info: null, // 收款人信息
        withdraws_list: [], // 打款记录
        share_num: 0, // 项目分享次数
        project_hide: 0, // 项目是否隐藏 1隐藏
        is_auto_reply: 0, // 是否开启自动感谢 1开启 2关闭 （主态）
        jsbrnd: store.get('jsbrnd'), // 随机数

        counselor: null, // 志愿者
        audit_state: 0,
    },
    mutations: {
        initProject(state, obj) {
            if (!obj) return;
            Object.keys(obj).forEach((key) => {
                if (key === 'state') {
                    state.project_state = obj.state;
                } else if (key === 'title') {
                    state.project_title = obj.title;
                } else if (key === 'hide') {
                    state.project_hide = obj.hide;
                } else if (key === 'uuid') {
                    state.projuuid = obj.uuid;
                } else {
                    if (!Object.keys(state).includes(key)) return false;
                    state[key] = obj[key];
                }
            });
        },

        initProjectPublicity(state, obj) {
            if (!obj) return;
            Object.keys(obj).forEach((key) => {
                if (!Object.keys(state).includes(key)) return false;
                state[key] = obj[key];
            });
        },

        resetCurrentPopup(state, payload) {
            state.current_popup = payload;
        },

        resetCurrentSubPopup(state, payload) {
            state.current_sub_popup = payload;
        },

        resetProjectState(state, payload) {
            state.project_state = payload;
        },

        resetProjectAutoReply(state, payload) {
            state.is_auto_reply = payload;
        },
    },
    actions: {},
})