import store from 'store';
import utils from './utils';
// import logTracker from './logTracker';
import alertMsg from './alert-msg';
// import { showToast, hideToast } from './totast-msg';
import { showLoading, hideLoading } from './modalloading';

if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line global-require
    // require('./config/devXHR');
}

window.alertMsg = alertMsg;
// window.showToast = showToast;
// window.hideToast = hideToast;
window.showLoading = showLoading;
window.hideLoading = hideLoading;

window.BasePath = 'https://m.jisuchou.net/';

window.APIBasePath = 'https://api.jisuchou.net/';


window.jsbrnd = store.get('jsbrnd');

if (!window.jsbrnd) {
    const rnd = Math.random();
    window.jsbrnd = rnd;
    store.set('jsbrnd', rnd);
}

let deviceData = {};
if (!store.get('godevice')) {
    deviceData.device_id = utils.createRndStrId();
    deviceData.uuid = '';
    deviceData.user_id = '';
} else {
    deviceData = store.get('godevice');
    deviceData.device_id = deviceData.device_id;
    deviceData.uuid = deviceData.uuid ? deviceData.uuid : '';
    deviceData.user_id = deviceData.user_id ? deviceData.user_id : '';
}
store.set('godevice', deviceData);

/* android禁止微信浏览器调整字体大小 */
function handleFontSize() {
    // 设置网页字体为默认大小
    WeixinJSBridge.invoke('setFontSizeCallback', {
        fontSize: 0,
    });
    // 重写设置网页字体大小的事件
    WeixinJSBridge.on('menu:setfont', () => {
        WeixinJSBridge.invoke('setFontSizeCallback', {
            fontSize: 0,
        });
    });
}

if (typeof WeixinJSBridge === 'object' && typeof WeixinJSBridge.invoke === 'function') {
    handleFontSize();
} else if (document.addEventListener) {
    document.addEventListener('WeixinJSBridgeReady', handleFontSize, false);
} else if (document.attachEvent) {
    document.attachEvent('WeixinJSBridgeReady', handleFontSize);
    document.attachEvent('onWeixinJSBridgeReady', handleFontSize);
}
/* android禁止微信浏览器调整字体大小 */

window.onerror = function (message, source, lineno, colno, error) {
    if (message.indexOf('WeixinJSBridge') < 0 && message.indexOf('WXJS') < 0) {
        logTracker.jsError(message, source, lineno, colno, error);
    }
};
