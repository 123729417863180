<template>
    <div v-show="show" class="alert-msg">
        <div class="alert-msg-text">{{text}}</div>
    </div>
</template>

<script>
export default {
    name: 'AlertMsg',

    data() {
        return {};
    },

    props: {
        text: String,
        show: Boolean,
    },
};
</script>

<style lang="less" scoped>
.alert-msg {
    position: fixed;
    display: flex;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.alert-msg-text {
    position: relative;
    top: -32px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 16px;
    font-size: 16px;
    min-width: 120px;
    text-align: center;
    border-radius: 5px;
    margin: 0 20px;
    word-break: break-all;
}
</style>
