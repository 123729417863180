// import Cookies from 'js-cookie';
import store from 'store';
import utils from './utils';
// import logTracker from './logTracker';

const rqObj = utils.getRequestParams();
const token64 = rqObj.server_token;


// var passportCookies = Cookies.get('Passport-Token-All');
// if(passportCookies){
//     store.set('Mmc-Token', passportCookies);
//     // setCookieForOldVersion(passportCookies);
// }
// function setCookieForOldVersion(longTokenStr){
//     var cookie_token = JSON.parse(longTokenStr);

//     Cookies.set('Mmc-Token', cookie_token.access_token, {
//         expires: 365,
//         path: '/',
//         domain: 'qschou.com'
//     });

//     Cookies.set('Mmc-Token-All', longTokenStr, {
//         expires: 365,
//         path: '/',
//         domain: 'qschou.com'
//     });

// }

if (token64) {
    const urlTokenStr = Base64.decode(window.decodeURIComponent(token64));

    try {
        store.set('Mmc-Token', urlTokenStr);
        // setCookieForOldVersion(urlTokenStr);

        const searchStr = location.search;
        const paramArr = searchStr.substr(1).split('&');
        const lastParamArr = [];

        paramArr.forEach((item) => {
            if (item.indexOf('server_token') === -1) {
                lastParamArr.push(item);
            }
        });

        let replaceStr = '';
        if (paramArr.length > 1) {
            replaceStr = `${location.origin + location.pathname}?${lastParamArr.join('&')}${location.hash}`;
        } else {
            replaceStr = location.origin + location.pathname + location.hash;
        }
        history.replaceState(null, null, replaceStr);
        location.replace(replaceStr);
    } catch (e) {
        // logTracker.customError(`${e.toString()},token format wrong save`);
        store.remove('Mmc-Token');
        // Cookies.set('Mmc-Token', null, {
        //     expires: -1,
        // });
        // Cookies.set('Mmc-Token-All', null, {
        //     expires: -1,
        // });
    }
} else {
    const localTokenStr = store.get('Mmc-Token');

    try {
        if (localTokenStr) {
            JSON.parse(localTokenStr);
        }
    } catch (e) {
        // logTracker.customError(`${e.toString()},token format wrong get local`);
        store.remove('Mmc-Token');
        // Cookies.set('Mmc-Token', null, {
        //     expires: -1,
        // });
        // Cookies.set('Mmc-Token-All', null, {
        //     expires: -1,
        // });
    }
}
