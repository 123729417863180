import Vue from 'vue';
import modalloading from './modalloading.vue';

let created = false;

let single;

function showLoading(s) {
    if (!created) {
        single = new (Vue.extend(modalloading))({
            el: document.createElement('div'),
        });
        if (document.body) {
            document.body.appendChild(single.$el);
        }

        created = true;
    }

    single.text = s;
    single.show = true;
}

function hideLoading() {
    if (single) {
        single.show = false;
    }
}

export {
    showLoading, hideLoading,
};
