import Vue from 'vue';
import AlertCpt from './AlertCpt';

let created = false;

let single;

function alertMsg(s, duration = 3000) {
    if (!created) {
        single = new (Vue.extend(AlertCpt))({
            el: document.createElement('div'),
        });
        if (document.body) {
            document.body.appendChild(single.$el);
        } else {
            // window.alert(s);
        }

        created = true;
    }

    single.text = s;
    single.show = true;


    setTimeout(() => {
        single.show = false;
    }, duration);
}

export default alertMsg;
