<template>
    <div class="qsc-modalloading" v-show="show">
        <div class="qsc-modalloading_backdrop"></div>
        <div class="qsc-modalloading_dialog">
            <div class="qsc-modalloading_content">
                <span>
                    <div class="icon-loading_big">
                        <div class="loading-icon-leaf loading-icon-leaf_0"></div>
                        <div class="loading-icon-leaf loading-icon-leaf_1"></div>
                        <div class="loading-icon-leaf loading-icon-leaf_2"></div>
                        <div class="loading-icon-leaf loading-icon-leaf_3"></div>
                        <div class="loading-icon-leaf loading-icon-leaf_4"></div>
                        <div class="loading-icon-leaf loading-icon-leaf_5"></div>
                        <div class="loading-icon-leaf loading-icon-leaf_6"></div>
                        <div class="loading-icon-leaf loading-icon-leaf_7"></div>
                        <div class="loading-icon-leaf loading-icon-leaf_8"></div>
                        <div class="loading-icon-leaf loading-icon-leaf_9"></div>
                        <div class="loading-icon-leaf loading-icon-leaf_10"></div>
                        <div class="loading-icon-leaf loading-icon-leaf_11"></div>
                    </div>
                    <span>{{text}}</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'index',

    data() {
        return {};
    },
    props: {
        text: String,
        show: Boolean,
    },
};
</script>

<style lang="css" scoped>
</style>
