import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [{
        //lidong
        path: '/',
        redirect: '/fund/publish/guidance',
    },
    {
        path: '/fund/home',
        name: 'home',
        meta: { title: '宠物筹' },
        component: () =>
            import ('../views/home/index.vue'),
    },
    {
        path: '/fund/indexList',
        name: 'indexList',
        meta: { title: '宠物筹' },
        component: () =>
            import ('../views/indexList/index.vue'),
    },
    {
        path: '/fund/contentSite',
        name: 'contentSite',
        meta: { title: '宠物筹' },
        component: () =>
            import ('../views/contentSite/index.vue'),
    },
    {
        //lidong
        path: '/fund/publish/guide',
        name: 'publish-guide',
        meta: { title: '宠物筹' },
        component: () =>
            import ('../views/publish/guide/index.vue'),
    },
    {
        //lidong
        path: '/fund/publish/lead',
        name: 'publish-guide',
        meta: { title: '宠物筹' },
        component: () =>
            import ('../views/publish/guide/index.vue'),
    },
    {
        //lidong
        path: '/fund/publish/guidance',
        name: 'publish-guide',
        meta: { title: '宠物筹' },
        component: () =>
            import ('../views/publish/guide/index.vue'),
    },
    {
        //lidong
        path: '/fund/publish/bind',
        name: 'publish-bind',
        meta: { title: '宠物筹' },
        component: () =>
            import ('../views/publish/bind/index.vue'),
    },
    {
        //lidong
        path: '/fund/publish/create',
        name: 'publish-create',
        meta: { title: '发布项目' },
        component: () =>
            import ('../views/publish/create/index.vue'),
    },
    {
        //lidong
        path: '/fund/publish/success',
        name: 'publish-success',
        meta: { title: '发布成功' },
        component: () =>
            import ('../views/publish/success/index.vue'),
    },
    {
        //lidong
        path: '/fund/publish/edit',
        name: 'publish-edit',
        meta: { title: '项目编辑' },
        component: () =>
            import ('../views/publish/edit/index.vue'),
    },
    {
        //liyi
        path: '/fund/project/detail',
        name: 'project-detail',
        meta: { title: '项目详情' },
        component: () =>
            import ('../views/project/detail/index.vue'),
    },
    {
        path: '/fund/project/confirmlist',
        name: 'confirm-list',
        meta: { title: '证实列表' },
        component: () =>
            import ('../views/project/confirm-list/index.vue'),
    },
    {
        path: '/fund/project/proveform',
        name: 'confirm-list',
        meta: { title: '帮TA证实' },
        component: () =>
            import ('../views/project/prove-form/index.vue'),
    },
    {
        //liyi
        path: '/fund/project/supporters',
        name: 'project-supporters',
        meta: { title: '支持记录' },
        component: () =>
            import ('../views/project/supporters/index.vue'),
    },
    {
        //wangjiang
        path: '/fund/project/report',
        name: 'project-report',
        meta: { title: '举报' },
        component: () =>
            import ('../views/project/report/index.vue'),
    },
    {
        //wangjiang
        path: '/fund/project/report-form',
        name: 'project-report-form',
        meta: { title: '举报' },
        component: () =>
            import ('../views/project/report-form/index.vue'),
    },
    {
        //wangjiang
        path: '/fund/project/payment',
        name: 'project-payment',
        meta: { title: '支付' },
        component: () =>
            import ('../views/project/payment/index.vue'),
    },
    {
        //wangjiang
        path: '/fund/project/paying',
        name: 'project-paying',
        meta: { title: '支付' },
        component: () =>
            import ('../views/project/paying/index.vue'),
    },
    {
        //wangjiang
        path: '/fund/project/paysuccess',
        name: 'project-paysuccess',
        meta: { title: '支付成功' },
        component: () =>
            import ('../views/project/paysuccess/index.vue'),
    },
    {
        // 项目更新动态
        path: '/fund/project/updateDynamic',
        name: 'project-updateDynamic',
        component: () =>
            import ('../views/project/updateDynamic/index.vue'),
    },
    {
        //liyi
        path: '/fund/withdraw/entry',
        name: 'withdraw-entry',
        meta: { title: '项目提现' },
        component: () =>
            import ('../views/withdraw/entry/index.vue'),
    },
    {
        //liyi
        path: '/fund/withdraw/applied',
        name: 'withdraw-applied',
        meta: { title: '项目提现' },
        component: () =>
            import ('../views/withdraw/applied/index.vue'),
    },
    {
        //wangjiang
        path: '/fund/withdraw/records',
        name: 'withdraw-records',
        meta: { title: '提现记录' },
        component: () =>
            import ('../views/withdraw/records/index.vue'),
    },
    {
        //wangjiang
        path: '/fund/withdraw/timeline',
        name: 'withdraw-timeline',
        meta: { title: '提现进度' },
        component: () =>
            import ('../views/withdraw/timeline/index.vue'),
    },
    {
        //liuzhuan
        path: '/fund/review/aided',
        name: 'review-aided',
        meta: { title: '患者验证' },
        component: () =>
            import ('../views/review/aided/index.vue'),
    },
    {
        //liuzhuan
        path: '/fund/review/payeeold',
        name: 'review-payee',
        meta: { title: '收款人验证' },
        component: () =>
            import ('../views/review/payeeold/index.vue'),
    },
    {
        //lidong
        path: '/fund/review/payee',
        name: 'review-payee',
        meta: { title: '收款人验证' },
        component: () =>
            import ('../views/review/payee/index.vue'),
    },
    {
        //lidong
        path: '/fund/my',
        name: 'my',
        meta: { title: '个人中心' },
        component: () =>
            import ('../views/my/index/index.vue'),
    },
    {
        //lidong
        path: '/fund/my/setting',
        name: 'setting',
        meta: { title: '个人设置' },
        component: () =>
            import ('../views/my/setting/index/index.vue'),
    },
    {
        //lidong
        path: '/fund/my/service',
        name: 'service',
        meta: { title: '客服咨询' },
        component: () =>
            import ('../views/my/service/index.vue'),
    },
    {
        //lidong
        path: '/fund/my/created',
        name: 'my-created',
        meta: { title: '我的筹款' },
        component: () =>
            import ('../views/my/created/index.vue'),
    },
    {
        //liyi
        path: '/fund/my/helped',
        name: 'my-helped',
        meta: { title: '我的支持' },
        component: () =>
            import ('../views/my/helped/index.vue'),
    },
    {
        //wangjiang
        path: '/fund/my/order',
        name: 'my-order',
        meta: { title: '订单详情' },
        component: () =>
            import ('../views/my/order/index.vue'),
    },
    {
        //liyi
        path: '/fund/helpcenter/list',
        name: 'helpcenter-list',
        meta: { title: '帮助中心' },
        component: () =>
            import ('../views/helpcenter/list/index.vue'),
    },
    {
        //?
        path: '/fund/helpcenter/form',
        name: 'helpcenter-form',
        component: () =>
            import ('../views/helpcenter/form/index.vue'),
    },
    {
        //?
        path: '/fund/helpcenter/success',
        name: 'helpcenter-success',
        component: () =>
            import ('../views/helpcenter/success/index.vue'),
    },
    {
        // 绿通激活表单页
        path: '/fund/greenchannel/form',
        name: 'greenchannel-form',
        component: () =>
            import ('../views/greenchannel/form/index.vue'),
    },
    {
        // 绿通激活成功
        path: '/fund/greenchannel/success',
        name: 'greenchannel-success',
        component: () =>
            import ('../views/greenchannel/success/index.vue'),
    },
    {
        // 绑定手机号
        path: '/fund/my/bindPhone',
        name: 'my-bindPhone',
        component: () =>
            import ('../views/my/setting/phone/bindPhone/index.vue'),
    },
    {
        // 换绑手机号
        path: '/fund/my/phonemodify',
        name: 'my-phonemodify',
        component: () =>
            import ('../views/my/setting/phone/phonemodify/index.vue'),
    },
    {
        // 绑定成功页
        path: '/fund/my/phonesuccess',
        name: 'my-phonesuccess',
        component: () =>
            import ('../views/my/setting/phone/phonesuccess/index.vue'),
    },
    {
        // 志愿者二级页
        path: '/fund/volunteer/detail',
        name: 'volunteer-detail',
        component: () =>
            import ('../views/volunteer/index.vue'),
    },
    {
        // 项目自动感谢回复
        path: '/fund/project/reply',
        name: 'project-reply',
        component: () =>
            import ('../views/project/reply/index.vue'),
    },
    {
        // abtest
        path: '/fund/abtest',
        name: 'setting-abtest',
        component: () =>
            import ('../views/ABtest/index.vue'),
    },
    {
        // onepoint apply
        path: '/fund/activity/onepoint/apply',
        name: 'onepoint-apply',
        meta: { title: '宠物粮助力活动' },
        component: () =>
            import ('../views/activity/onepoint/apply/index.vue'),
    },
    {
        // onepoint applySuccess
        path: '/fund/activity/onepoint/applySuccess',
        meta: { title: '宠物粮助力活动' },
        name: 'onepoint-applySuccess',
        component: () =>
            import ('../views/activity/onepoint/applySuccess/index.vue'),
    },
    {
        // onepoint detail
        path: '/fund/activity/onepoint/detail',
        name: 'onepoint-detail',
        meta: { title: '宠物粮助力活动' },
        component: () =>
            import ('../views/activity/onepoint/detail/index.vue'),
    },
    {

        path: '/fund/activity/lovesound/index',
        name: 'lovesound-index',
        meta: { title: '流浪动物守护行动' },
        component: () =>
            import ('../views/activity/lovesound/index/index.vue'),
    },
    {

        path: '/fund/activity/lovesound/detail',
        meta: { title: '活动项目详情' },
        name: 'lovesound-detail',
        component: () =>
            import ('../views/activity/lovesound/detail/index.vue'),
    },
    {
        // onepoint rankList
        path: '/fund/activity/onepoint/rankList',
        name: 'onepoint-rankList',
        component: () =>
            import ('../views/activity/onepoint/rankList/index.vue'),
    },
    {
        // onepoint paySuccess
        path: '/fund/activity/onepoint/paySuccess',
        name: 'onepoint-paySuccess',
        meta: { title: '支付成功' },
        component: () =>
            import ('../views/activity/onepoint/paySuccess/index.vue'),
    },
    {
        // common paytable
        path: '/fund/project/payment/paytableonepoint',
        name: 'paytableonepoint',
        component: () =>
            import ('../views/project/paytableonepoint/index.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes,
});

router.afterEach((to, from) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    } else {
        document.title = '宠物筹';
    }
})

export default router;